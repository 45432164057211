<template>
  <div class="page">
    <div class="page-main">
      <el-form
        class="page-card"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item :label="$t('device.deviceName')">
          <el-input
            v-model="form.deviceName"
            :placeholder="$t('device.deviceNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.macAddress')" prop="macAddress">
          <input-mac
            v-model="form.macAddress"
            class="form-item-width"
            :disabled="pageType"
          ></input-mac>
        </el-form-item>
        <el-form-item :label="$t('device.snCode')" prop="snCode">
          <el-input
            v-model="form.snCode"
            :placeholder="$t('device.snCodePla')"
            :disabled="pageType"
            :maxlength="64"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.deviceModel')" prop="deviceModel">
          <select-model v-model="form.deviceModel"></select-model>
        </el-form-item>
        <el-form-item :label="$t('device.site')" prop="site">
          <select-site
            v-model="form.site"
            defaultShow
            @change="siteChange"
          ></select-site>
        </el-form-item>
        <el-form-item :label="$t('device.bindAccount')">
          <div class="assign-device-item" v-for="(item, i) in form.list" :key="i">
            <div class="assign-device-values">
              <el-select v-model="item.no" :disabled="MACRight">
                <el-option
                  v-for="item in accountNoList"
                  :key="item.id"
                  :label="item.accountNo"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select v-model="item.accountId" :disabled="MACRight" :placeholder="$t('device.accountSelectPlaceholder')">
              <el-option
                  class="account-list"
                  v-for="item in getUserIdList(accountList, i)"
                  :key="item.id"
                  :label="item.sipUserId"
                  :value="item.id">
                  <el-tooltip effect="dark" :content="$t('sipAccount.assigned')" placement="left" :disabled="!item.status">
                    <i
                      :class="['el-icon-s-custom', { 'text-success': !!item.status }, 'icon']"
                      :title="accountStatus[item.status]"
                    ></i>
                  </el-tooltip>
                  <span :title="item.sipUserId">
                    {{ item.sipUserId }}
                    <span v-if="item.accountName">({{ item.accountName }})</span>
                  </span>
                  <span class="right">
                    {{ item.serverName }}
                    <span v-if="item.sipServer">({{ item.sipServer }})</span>
                  </span>
                </el-option>
              </el-select>
            </div>
            <div class="assign-device-delete" @click="deleteBindAccount(i)">
              <el-tooltip effect="dark" :content="$t('delete')" placement="top" :open-delay="1000">
                <i class="el-icon-circle-close"></i>
              </el-tooltip>
            </div>
          </div>
          <div class="assign-device-add" @click="addBindAccount">
            <el-button
              v-if="form.list.length < accountNoList.length || form.list.length === 0"
              icon="el-icon-plus"
              type="primary"
              :disabled="MACRight || !accountNoList.length"
            >{{ $t('add') }}</el-button>
          </div>
        </el-form-item>
        <el-form-item class="footer-btns">
          <el-button class="footer-btn" type="primary" plain @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
          <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { InputMac, SelectSite, SelectModel } from '@/components'
import { macFormat } from '@/plugins/methods'
import { accountConfigQuery } from '@/api/sipAccount'
import { deviceQuery, deviceAdd, deviceUpdate, deviceModelAccountNum, verifyMac } from '@/api/device'
import { pushDeviceConfig } from '@/api/setParam'

export default {
  name: 'DeviceDetails',
  components: {
    InputMac,
    SelectSite,
    SelectModel,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        this.MACRight = true
        callback(new Error(this.$t('device.macAddressRule')))
      } else if (!macFormat(value)) {
        this.MACRight = true
        callback(new Error(this.$t('device.macAddressRule')))
      } else {
        this.MACRight = false
        callback()
      }
    }
    return {
      oldsite: '',
      pageType: this.$route.query.id ? true : false,
      MACRight: true,
      accountList: [],
      oldAccountList: [],
      accountNoList: [],
      currentAccountList:[],
      form: {
        deviceName: '',
        macAddress: '',
        snCode: '',
        deviceModel: '',
        site: '',
        list: [],
      },
      rules: {
        macAddress: {
          required: true,
          validator: validatePass,
          trigger: 'change',
        },
        snCode: {
          required: true,
          message: this.$t('device.snCodeRule'),
          trigger: 'blur',
        },
        site: {
          required: true,
          message: this.$t('device.siteRule'),
          trigger: 'change',
        },
        deviceModel: {
          required: true,
          message: this.$t('device.deviceModelRule'),
          trigger: 'change',
        }
      },
      model:'',
    }
  },
  computed: {
    ...mapState('session', ['isLAN']),
    ...mapGetters('dist', ['accountStatus']),
  },
  watch: {
    MACRight(val) {
      !this.pageType && !val && this.checkMacAddress()
    },
    'form.deviceModel'() {
      this.getDeviceModelAccountNum()
    }
  },
  created() {
    this.pageType && this.getData(this.$route.query.id)
  },
  methods: {
    // 获取数据
    getData(id) {
      deviceQuery({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0].result.rows[0]
          if (data.result.rows[0].result.rows[0].list == null) {
            this.form.list = []
          }
          this.form.macAddress = this.form.macAddress.toUpperCase()
          this.oldsite = this.form.site
          this.getListAccount()
          this.getAccountIdList()
        })
        .catch(() => {})
    },
    // 检查mac
    checkMacAddress() {
      verifyMac({ macAddress: this.form.macAddress })
        .then(res => {
          console.log('查询mac', res)
          if (!this.isLAN) this.form.deviceModel = 'M3'
        })
    },
    // 获取账号个数
    getDeviceModelAccountNum() {
      deviceModelAccountNum({ model: this.form.deviceModel }, true)
        .then(({ data }) => {
          let accountNoList = []
          data.result.rows.forEach((e) => {
            accountNoList.push({
              id: e,
              accountNo: this.$t('device.account') + e,
            })
          })
          this.accountNoList = accountNoList
          if (this.form.list.length > accountNoList.length) this.form.list = []
        })
        .catch(() => {})
    },
    addBindAccount() {
      if (!this.MACRight) {
        this.form.list.push({
          accountId: null,
          no: this.accountNoList[0].id,
        })
      }
    },
    deleteBindAccount(i) {
      this.form.list.splice(i, 1)
    },
    siteChange(i) {
      if (i !== 'init') {
        this.form.list.map((e) => (e.accountId = null))
      }
      if (this.form.site) {
        this.getAccountIdList()
      } else {
        this.accountList = []
      }
    },
    //获取账号列表
    getAccountIdList() {
      accountConfigQuery({
        siteId: this.form.site,
        deviceId: this.$route.query.id,
      }).then((res) => {
        if (res.data.code == 0) {
          this.accountList = res.data.result.rows
          this.oldAccountList = JSON.parse(JSON.stringify(this.accountList))
        }
      })
    },
    // 保存
    submit() {
      if (this.form.list.length !== 0 && this.oldsite !== this.form.site && this.oldsite) {
        this.$message({
          message: this.$t('device.sitechange'),
          type: 'error',
        })
      } else {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.form.list = this.form.list.filter((e) => e.accountId)
            const submitApi = this.pageType ? deviceUpdate : deviceAdd
            submitApi(this.form)
              .then(({ data }) => {
                if (this.$route.query.pushStatus == 0 || this.$route.path === "/device/addDevice") {
                  this.$message.success(data.message)
                  this.$router.go(-1)
                } else {
                  this.push()
                }
              })
              .catch(( {data} ) => {
                console.log('submit error ')
                  if (data.messageExt != null) {
                  let idx = data.messageExt.search('Duplicate entry') 
                  if (idx >= 0) {
                     this.$message.error(this.$t('device.duplicateDevice'))
                  }
                }
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    push() {
      this.$confirm(this.$t('sitParam2.tipStr'), this.$t('sitParam2.tipTitle'), {
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no'),
        type: 'success',
        center: true,
      })
        .then(() => {
          pushDeviceConfig(this.$route.query.id)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$router.go(-1)
        })
      },
    getUserIdList(arr, index) {
      // 需求说明： 账号有两种来源。一种来自edm，对所有话机所有账号可见。一种来自某个话机上报，只对该设备且该账号可见
      let newArr = JSON.parse(JSON.stringify(arr))
      let currentBindAccount = this.oldAccountList[index]
      let bound = currentBindAccount && currentBindAccount.accountId
      // if (index == 0) {
      //   console.log(JSON.stringify(currentBindAccount))
      // }

      // 1. 让当前绑定的账号显示在最上面
      if (bound) {
        newArr.unshift(currentBindAccount)
      }

      // 2. 去除重复account
      if (bound) {
        for (let i = 1; i < newArr.length; i++) {
          if (newArr[i].id === currentBindAccount.id) {
            currentBindAccount.status = newArr[i].status
            currentBindAccount.accountName = newArr[i].accountName
            newArr.splice(i, 1)
            break
          }
        }
      }

      // 3. 删除除了当前绑定的账号之外的origin=2的账号
      let idToDelete = []
      let indexStart
      if (bound) {
        indexStart = 1
      }else{
        indexStart = 0
      }
      for (let i = indexStart; i < newArr.length; i++) {
        if(newArr[i].origin===2) {
          idToDelete.push(newArr[i].id)
        }
      }
      for (let i = 0; i < idToDelete.length; i++) {
        for (let j = 0; j < newArr.length; j++) {
          if (idToDelete[i] === newArr[j].id) {
            newArr.splice(j, 1)
            break
          }
        }
      }
      return newArr
    }
  }
}
</script>

<style lang="scss" scoped>
@include form-page;
.assign-device-item {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  .assign-device-values {
    vertical-align: top;
    display: flex;
    justify-content: space-between;
    width: 100%;
    ::v-deep .el-select {
      // flex: 1;
      position: relative;
      width: calc((100% - 16px) / 2);
      .el-form-item__error {
        position: relative;
        top: 4px;
      }
    }
  }
}
.assign-device-delete {
  position: absolute;
  right: -51px;
  font-size: 30px;
}
.assign-device-add {
  cursor: pointer;
  display: inline-block;
  width: 112px;
  font-size: 24px;
  margin-bottom: 10px;
}
.account-list {
  .icon {
    margin-right: 5px;
  }
  .right {
    float: right;
  }
}
</style>
